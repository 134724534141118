import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddPackage = Loadable(lazy(() => import('./AddPackage')));
const PackageList = Loadable(lazy(() => import('./PackageList')));

const PackageRoute = [
	{
		path: '/dashboard/packagelist',
		element: <PackageList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/packageadd',
		element: <AddPackage />,
		auth: authRoles.admin,
	},
];

export default PackageRoute;
