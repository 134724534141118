import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteproductDispatch } from 'reducers/HomeReducer';

export default function ProductTable() {
	const { product, loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const columns = [
		{ field: 'id', headerName: 'ID', width: 90 },
		{
			field: 'name',
			headerName: 'Name',
			width: 150,
			editable: true,
		},
		{
			field: 'product_type',
			headerName: 'Product Type',
			width: 150,
			editable: true,
		},
		{
			field: 'description',
			headerName: 'Description',
			type: 'number',
			width: 350,
			editable: true,
		},
		{
			field: 'price',
			headerName: 'Price',
			width: 150,
			editable: true,
		},
		{
			field: 'offer_price',
			headerName: 'Offer Price',
			width: 150,
			editable: true,
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 300,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button
							variant='contained'
							color='primary'
							onClick={(event) => {
								handleClick(event, cellValues);
								// console.log('id', cellValues.id);
							}}>
							Edit
						</Button>
						<Button
							variant='contained'
							color='primary'
							style={{ marginLeft: 10 }}
							onClick={(event) => {
								handleClickDelete(event, cellValues);
							}}>
							Delete
						</Button>
					</Box>
				);
			},
		},
	];
	const handleClick = (event, cellValues) => {};
	const handleClickDelete = (event, cellValues) => {
		// dispatch(deleteproductDispatch(cellValues.id, loginInfo.token));
	};
	return (
		<Box sx={{ height: 400, width: '100%' }}>
			{product?.data.length > 0 ? (
				<DataGrid
					rows={product?.data}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			) : (
				''
			)}
		</Box>
	);
}
