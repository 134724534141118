import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddBanner = Loadable(lazy(() => import('./AddGallery')));
const BannerList = Loadable(lazy(() => import('./GalleryList')));

const GalleryRoutes = [
	{
		path: '/dashboard/gallerylist',
		element: <BannerList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addgallery',
		element: <AddBanner />,
		auth: authRoles.admin,
	},
];

export default GalleryRoutes;
