import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddPopularDestination = Loadable(
	lazy(() => import('./AddPopularDestination'))
);
const PopularDestinationList = Loadable(
	lazy(() => import('./PopularDestinationList'))
);

const PopularDestinationRoutes = [
	{
		path: '/dashboard/pslist',
		element: <PopularDestinationList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addps',
		element: <AddPopularDestination />,
		auth: authRoles.admin,
	},
];

export default PopularDestinationRoutes;
