import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./ProductSection')));
const SectorList = Loadable(lazy(() => import('./SectorList')));

const SectorRoutes = [
	{
		path: '/dashboard/newproduct',
		element: <Analytics />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/sectorlist',
		element: <SectorList />,
		auth: authRoles.admin,
	},
];

export default SectorRoutes;
