import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { Lazy } from 'yup';

const CompanyInfo = Loadable(lazy(() => import('./CompanyInfo')));
const PrivacyPolicy = Loadable(lazy(() => import('./PrivacyPolicy')));
const CompanyPolicy = Loadable(lazy(() => import('./CompanyPolicy')));
const UserAgreement = Loadable(lazy(() => import('./UserAgreeMent')));
const TermandCondition = Loadable(lazy(() => import('./TermandConditon')));
const Aboutus = Loadable(lazy(() => import('./AboutUs')));
const Career = Loadable(lazy(() => import('./Career')));
const Disclaimer = Loadable(lazy(() => import('./Disclaimer')));
const Contact = Loadable(lazy(() => import('./Contact')));
const Baggage = Loadable(lazy(() => import('./BaggageAllowance')));
const Cancellation = Loadable(lazy(() => import('./Cancellation')));
const CustomerCare = Loadable(lazy(() => import('./CustomerCare')));
const FAQ = Loadable(lazy(() => import('./FAQ')));
const DomesticFlight = Loadable(lazy(() => import('./DomesticFlight')));

const FooterRoutes = [
	{
		path: '/dashboard/companyinfo',
		element: <CompanyInfo />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/privacypolicy',
		element: <PrivacyPolicy />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/companypolicy',
		element: <CompanyPolicy />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/useragreement',
		element: <UserAgreement />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/termandcondition',
		element: <TermandCondition />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/aboutus',
		element: <Aboutus />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/career',
		element: <Career />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/disclaimer',
		element: <Disclaimer />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/contact',
		element: <Contact />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/baggageallowance',
		element: <Baggage />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/cancellationrefund',
		element: <Cancellation />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/customercare',
		element: <CustomerCare />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/faq',
		element: <FAQ />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/domesticflight',
		element: <DomesticFlight />,
		auth: authRoles.admin,
	},
];

export default FooterRoutes;
