import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, Box, Button, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	ManageMarkupUpdateDispatch,
	addFundAgentDispatch,
} from 'reducers/HomeReducer';
const title = ['%', 'Rs'];

const DefaultMarkupForm = () => {
	const { agentId } = useSelector((state) => state.ui);
	const { loginInfo, manageMarkup } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const handleFormSubmit = (data) => {
		console.log(data);
		let form = {
			markup: [
				{
					agentId: 0,
					inventory: 'Flight',
					value: data.flight_value,
					type: data.flight_type,
				},
				{
					agentId: 0,
					inventory: 'Intflight',
					value: data.intflight_value,
					type: data.intflight_type,
				},
				{
					agentId: 0,
					inventory: 'Hotel',
					value: data.hotelvalue,
					type: data.hoteltype,
				},
				{
					agentId: 0,
					inventory: 'Inthotel',
					value: '0',
					type: '%',
				},
				{
					agentId: 0,
					inventory: 'Bus',
					value: data.busvalue,
					type: data.bustype,
				},
				{
					agentId: 0,
					inventory: 'Car',
					value: data.car_value,
					type: data.cartype,
				},
				{
					agentId: 0,
					inventory: 'Tour',
					value: data.tour_type,
					type: data.tour_value,
				},
			],
		};
		dispatch(ManageMarkupUpdateDispatch(form));
	};
	if (manageMarkup) {
		let val = '';
		val = manageMarkup?.result.map((i) => {
			return i.inventory === 'Flight' ? 2 : '';
		});
		console.log('val', val);
	}

	return (
		<Formik
			initialValues={{
				flight_value: manageMarkup?.result[0]?.value,
				flight_type: manageMarkup?.result[0]?.type,
				intflight_value: manageMarkup?.result[1]?.value,
				intflight_type: manageMarkup?.result[1]?.type,
				busvalue: manageMarkup?.result[2]?.value,
				bustype: manageMarkup?.result[2]?.type,
				hotelvalue: manageMarkup?.result[3]?.value,
				hoteltype: manageMarkup?.result[3]?.type,
				car_value: manageMarkup?.result[4]?.value,
				cartype: manageMarkup?.result[4]?.type,
				tour_value: manageMarkup?.result[5]?.value,
				tour_type: manageMarkup?.result[5]?.type,
			}}
			enableReinitialize={true}
			onSubmit={handleFormSubmit}>
			{(formik) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box style={{ padding: 20 }}>
							<Box
								style={{
									padding: 30,
									background: 'white',
								}}>
								<h2>Default Markup</h2>
								<Box display={'flex'} justifyContent={'space-between'}>
									<Box>
										<h2>Inventory </h2>
									</Box>
									<Box>
										<h2>Value </h2>
									</Box>
									<Box>
										<h2>Type </h2>
									</Box>
								</Box>
								<Divider />
								<Box
									style={{ padding: 10 }}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}>
									<Box>
										<span style={{ fontSize: 16 }}>Flight </span>
									</Box>
									<Box>
										<Field
											name='flight_value'
											type='number'
											placeHolder='Enter Value'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.flight_value
													? '2px solid red'
													: '1px solid lightgrey',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.flight_value ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.flight_value}
											</span>
										) : null}
									</Box>
									<Box>
										<Field
											name={'flight_type'}
											as='select'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											{title.map((item, index) => (
												<option value={item}>{item}</option>
											))}
										</Field>
									</Box>
								</Box>
								<Divider />
								<Box
									style={{ padding: 10 }}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}>
									<Box>
										<span style={{ fontSize: 16 }}>Int. Flight </span>
									</Box>
									<Box>
										<Field
											name='intflight_value'
											type='number'
											placeHolder='Enter Value'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.amount
													? '2px solid red'
													: '1px solid lightgrey',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.intflight_value ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.intflight_value}
											</span>
										) : null}
									</Box>
									<Box>
										<Field
											name={'intflighttype'}
											as='select'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											{title.map((item, index) => (
												<option value={item}>{item}</option>
											))}
										</Field>
									</Box>
								</Box>
								<Divider />
								<Box
									style={{ padding: 10 }}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}>
									<Box>
										<span style={{ fontSize: 16 }}>Bus</span>
									</Box>
									<Box>
										<Field
											name='busvalue'
											type='number'
											placeHolder='Enter Value'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.busvalue
													? '2px solid red'
													: '1px solid lightgrey',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.busvalue ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.busvalue}
											</span>
										) : null}
									</Box>
									<Box>
										<Field
											name={'bustype'}
											as='select'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											{title.map((item, index) => (
												<option value={item}>{item}</option>
											))}
										</Field>
									</Box>
								</Box>
								<Divider />
								<Box
									style={{ padding: 10 }}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}>
									<Box>
										<span style={{ fontSize: 16 }}>Hotel</span>
									</Box>
									<Box>
										<Field
											name='hotelvalue'
											type='number'
											placeHolder='Enter Value'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.hotelvalue
													? '2px solid red'
													: '1px solid lightgrey',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.hotelvalue ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.hotelvalue}
											</span>
										) : null}
									</Box>
									<Box>
										<Field
											name={'hoteltype'}
											as='select'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											{title.map((item, index) => (
												<option value={item}>{item}</option>
											))}
										</Field>
									</Box>
								</Box>
								<Divider />
								<Box
									style={{ padding: 10 }}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}>
									<Box>
										<span style={{ fontSize: 16 }}>Car</span>
									</Box>
									<Box>
										<Field
											name='car_value'
											type='number'
											placeHolder='Enter Value'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.car_value
													? '2px solid red'
													: '1px solid lightgrey',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.car_value ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.car_value}
											</span>
										) : null}
									</Box>
									<Box>
										<Field
											name={'cartype'}
											as='select'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											{title.map((item, index) => (
												<option value={item}>{item}</option>
											))}
										</Field>
									</Box>
								</Box>
								<Divider />
								<Box
									style={{ padding: 10 }}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}>
									<Box>
										<span style={{ fontSize: 16 }}>Tour</span>
									</Box>
									<Box>
										<Field
											name='tour_value'
											type='number'
											placeHolder='Enter Value'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.tour_value
													? '2px solid red'
													: '1px solid lightgrey',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.tour_value ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.tour_value}
											</span>
										) : null}
									</Box>
									<Box>
										<Field
											name={'tour_type'}
											as='select'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											{title.map((item, index) => (
												<option value={item}>{item}</option>
											))}
										</Field>
									</Box>
								</Box>
								<Divider />
								<Grid container style={{ marginTop: 15 }} spacing={4}>
									<Grid item xs={12} lg={4}></Grid>
									<Grid item xs={12} lg={4}>
										<Button
											type='submit'
											style={{
												background: 'rgb(25, 118, 210)',
												color: 'white',
												// padding: 16,
												borderRadius: 5,
												width: '100%',
											}}>
											Update
										</Button>
									</Grid>
									<Grid item xs={12} lg={4}></Grid>
								</Grid>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default DefaultMarkupForm;
