import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./CustomerSection')));
const AddCustomer = Loadable(lazy(() => import('./AgentTransaction')));
const AgentView = Loadable(lazy(() => import('./AgentView')));

const CustomerRoutes = [
	{
		path: '/dashboard/customerlist',
		element: <Analytics />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/agenttrans',
		element: <AddCustomer />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/agentview',
		element: <AgentView />,
		auth: authRoles.admin,
	},
];

export default CustomerRoutes;
