import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddBanner = Loadable(lazy(() => import('./AddOffice')));
const BannerList = Loadable(lazy(() => import('./OfficeList')));

const OfficeRoutes = [
	{
		path: '/dashboard/officelist',
		element: <BannerList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addoffice',
		element: <AddBanner />,
		auth: authRoles.admin,
	},
];

export default OfficeRoutes;
