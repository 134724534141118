import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FlightList = Loadable(lazy(() => import('./FlightList')));
const FlightBookingList = Loadable(lazy(() => import('./FlightBookingList')));
const BookingDetail = Loadable(lazy(() => import('./BookingDetail')));

const FlightRoutes = [
	{
		path: '/dashboard/flightlist',
		element: <FlightList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/flightbooking',
		element: <FlightBookingList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/flightbooking/bookingdetail',
		element: <BookingDetail />,
		auth: authRoles.admin,
	},
];

export default FlightRoutes;
