import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const TransList = Loadable(lazy(() => import('./TransList')));

const TransRoutes = [
	{
		path: '/dashboard/transList',
		element: <TransList />,
		auth: authRoles.admin,
	},
];

export default TransRoutes;
