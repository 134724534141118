import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const MarkupList = Loadable(lazy(() => import('./MarkupList')));

const MarkupRoutes = [
	{
		path: '/dashboard/markupList',
		element: <MarkupList />,
		auth: authRoles.admin,
	},
];

export default MarkupRoutes;
