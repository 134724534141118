import DefaultMarkupForm from 'app/form/DefaultMarkupForm';
import MarkupForm from 'app/form/MarkupForm';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showManageMarkupDispatch } from 'reducers/HomeReducer';

const DefaultMarkup = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		let form = {
			agentId: 0,
		};
		dispatch(showManageMarkupDispatch(form));
	}, []);
	return (
		<div>
			<DefaultMarkupForm />
		</div>
	);
};

export default DefaultMarkup;
