import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Profile = Loadable(lazy(() => import('./Profile')));
const ChangePassword = Loadable(lazy(() => import('./ChangePassword')));

const ProfileRoutes = [
	{
		path: '/dashboard/profile',
		element: <Profile />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/changepassword',
		element: <ChangePassword />,
		auth: authRoles.admin,
	},
];

export default ProfileRoutes;
